import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';

const WazaiFounderPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <FeatureSectionLeft
          imageSrc="/wazai/founder.jpg"
          imageAlt="Image 1"
          title="創業者の想い"
          founderName="代表取締役 三村 和礼"
          description={`作業療法士として北九州市内の病院で介護の仕事に十数年たずさわる中で、都市部高齢者の抱える「食事」や「社会的孤立」という問題に直面してきました。
        きちんとした食事をとることが難しかったり、何日も人と話しをすることがなかったりする方に多く出会ってきました。

        おいしい家庭料理を食べて健康的な生活を送ってもらいたい、
        気軽に立ち寄ることができて何気ない会話ができる団らんの場をつくりたい、
        そんな想いで「団らん処 和菜屋」は始まりました。`}
        />
        <FeatureSectionRight
          imageSrc="/wazai/section1.jpg"
          imageAlt="Image 2"
          title="「食」へのこだわり"
          description={`
        小倉南区合馬にある自社農園『和彩屋農園』や地元農家からの新鮮な産直野菜、
        北九州卸売市場で毎朝仕入れた新鮮な食材、北海道の生産者さんから直接取り寄せた昆布でとった出汁など、こだわりの食材をふんだんに使っています。

        調理を手掛けるのは、ベテラン主婦のスタッフたちです。
        豊富な種類のおいしい家庭料理を食べてもらえるよう、時期毎に入る様々な食材に合わせて、手間暇を惜しまず真心を込めて一品一品すべて手作りしています。
        `}
          sectionId="section2"
        />
        <FeatureSectionLeft
          imageSrc="/wazai/wazai111.webp"
          imageAlt="Image 1"
          title="皆様へのメッセージ"
          description={`
        創業して8年目を迎えました。ありがたいことに、「ここのお惣菜なら、おばあちゃんが食べてくれる」「ここは、自分の家のようなものでありがたい」など嬉しいお声をいただいています。また、お客様同士が気軽に声をかけ合うなど、とても良い雰囲気の多世代交流の場にもなってきています。これも、日頃から和菜屋を理解し、応援してくださっている皆様のおかげと心から感謝しております。​大変なことの多い世の中ですが、これからも一層励んでまいりますので、どうぞよろしくお願いいたします。
        `}
        />
      </Layout>
    </div>
  );
};

export default WazaiFounderPage;

export const Head = () => (
  <Seo
    title={'創業者の想い'}
    description={'団らん処 和菜屋 創業者の想いについてのページです。'}
  />
);
